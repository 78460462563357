const state = {
    job: null,
  };
  
  const actions = {
    emitJob({ commit }, job) {
      commit('SET_JOB', job);
    }
  };
  
  const mutations = {
    SET_JOB(state, job) {
      state.job = job;
    },
  };
  
  export default {
    namespaced: true,
    state,
    actions,
    mutations,
  };