import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/styles/global.css'
import '@/components/shared'
import '@/misc/rules'
import './misc/filters'
import VueSocialSharing from 'vue-social-sharing'
import toast from '@/misc/toast'
import httpErrorHandler from '@/misc/error'
import setupInterceptors from '@/misc/interceptor'
import LoadScript from 'vue-plugin-load-script'
import VueGtag from 'vue-gtag'

Vue.config.productionTip = false
Vue.prototype.$toast = toast
Vue.prototype.$handleHttpError = httpErrorHandler

Vue.use(VueSocialSharing)

Vue.use(VueGtag, {
  config: { id: "G-B9H0188S0E" }
}, router)

new Vue({
  router,
  store,
  vuetify,
  LoadScript,
  VueGtag,
  created () {
    setupInterceptors(store)
  },
  render: h => h(App)
}).$mount('#app')
