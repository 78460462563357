import store from '../store'

const httpErrorHandler = (error) => {
  if (error.response && error.response.data.message) {
    store.dispatch('notifier/showError', error.response.data.message)
  } else {
    store.dispatch(
      'notifier/showError',
      'Ops! Algo deu errado. Tente novamente mais tarde! :('
    )
  }
}

export default httpErrorHandler
