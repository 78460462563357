import api from './api'

const getAll = () => {
  return api.get('institutional-text')
}

const findBySlug = (slug) => api.get(`institutional-text/${slug}`)

const getCategories = () => api.get('category/actives/')

const getInstitutionalTexts = () => api.get('institutional-text/')

export { getAll, findBySlug, getCategories, getInstitutionalTexts }
