<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="8">
        <h2 class="text-lg-h3">
          {{ article.headline }}
        </h2>
        <p class="text-lg-h6 mt-5">
          {{ article.caption }}
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        class="d-flex flex-wrap justify-center justify-md-space-between"
        cols="8"
      >
        <div class="d-flex flex-column">
          <p class="font-weight-bold mb-0">
            Por {{ article.author }}
          </p>
          <p>
            {{ article.createdAt | dateFormatted }} -
            {{ article.createdAt | hourFormatted }}
          </p>
        </div>

        <div
          v-if="shareOnNetwork"
          class="d-flex"
        >
          <ShareNetwork
            v-for="network in networks"
            :key="network.name"
            title="Cassems Informa"
            :network="network.name"
            :url="url"
          >
            <v-btn
              :color="network.iconColor"
              icon
            >
              <v-icon>{{ network.icon }}</v-icon>
            </v-btn>
          </ShareNetwork>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="8">
        <v-img
          class="white--text align-center"
          max-height="400"
          :src="article.featuredImage"
        />
      </v-col>
    </v-row>

    <v-row
      class="mt-10"
      justify="center"
    >
      <v-col
        cols="8"
        v-html="article.text"
      />
    </v-row>
    <v-row
      v-if="galleries?.length > 0"
      justify="center"
    >
      <v-col cols="8">
        <p class="text-uppercase px-2 text-h6">
          mais fotos
        </p>
        <gallery-carousel :galleries="galleries" />
      </v-col>
    </v-row>
    <v-row
      v-if="article.attc?.length > 0"
      justify="center"
    >
      <attachment-card :attachment="article.attc" />
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ReadArticle',
  components: {
    'gallery-carousel': () => import('@/components/GalleryCarousel'),
    'attachment-card': () => import('@/components/AttachmentCards')
  },
  props: {
    article: {
      type: Object,
      default: () => ({})
    },
    shareOnNetwork: {
      type: Boolean,
      default: () => false
    },
    galleries: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      networks: [
        {
          name: 'facebook',
          icon: 'mdi-facebook',
          iconColor: 'blue'
        },
        {
          name: 'twitter',
          icon: 'mdi-twitter',
          iconColor: 'blue darken-3'
        },
        {
          name: 'linkedin',
          icon: 'mdi-linkedin',
          iconColor: 'blue darken-4'
        },
        {
          name: 'whatsapp',
          icon: 'mdi-whatsapp',
          iconColor: 'green'
        }
      ],
      url: window.location.href
    }
  },
}
</script>
