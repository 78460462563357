import router from '@/router'

const state = {
  token: JSON.parse(window.localStorage.getItem('cassems_token')),
  user: window.localStorage.getItem('cassems_user'),
  // tokenGLPI: JSON.parse(window.localStorage.getItem('cassems_token_session')),
  login: JSON.parse(window.localStorage.getItem('cassems_user_ad'))
}
const mutations = {
  login (state, payload) {
    window.localStorage.setItem('cassems_token', JSON.stringify(payload.token))
    state.token = payload.token
    window.localStorage.setItem('cassems_user', payload.name)
    state.user = payload.name
    window.localStorage.setItem('cassems_token_session', JSON.stringify(payload.session_token_glpi))
    state.tokenGLPI = payload.session_token_glpi
    window.localStorage.setItem('cassems_user_ad', JSON.stringify(payload.login))
    state.login = payload.login
  },
  logout (state) {
    window.localStorage.removeItem('cassems_token')
    window.localStorage.removeItem('cassems_user')
    window.localStorage.removeItem('cassems_token_session')
    window.localStorage.removeItem('cassems_user_ad')
    state.token = undefined
    state.user = undefined
    state.tokenGLPI = undefined
    state.login = undefined
  }
}
const actions = {
  async login ({ commit }, payload) {
    commit('login', payload)
  },
  async logout ({ commit }) {
    commit('logout')
    router.push({ name: 'auth.login' })
  }
}
const getters = {
  isLogged (state) {
    return !!state.token
  },
  token (state) {
    return state.token
  },
  userName (state) {
    return state?.user
  },
  tokenGLPI (state) {
    return state.tokenGLPI
  },
  userAd (state) {
    return state.login
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
