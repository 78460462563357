<template>
  <v-container>
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdn-prod.securiti.ai/consent/cookie-consent.css"
    >
    <v-btn
      color="secondary"
      class="cmp-revoke-consent"
    >
      Rever Cookies
    </v-btn>
  </v-container>
</template>

<script>
import { loadScript } from 'vue-plugin-load-script'

export default {
  name: 'PrivacyCookies',
  mounted () {
    this.initPrivacyCookies()
    loadScript('https://cdn-prod.securiti.ai/consent/auto_blocking/3606043f-db40-4103-bbba-201f3c51db3a/da0128c5-daed-4bee-910f-39d0d95aedbc.js')
      .then(() => {
        const privacyScript = document.createElement('script')
        privacyScript.setAttribute('url', 'https://cdn-prod.securiti.ai/consent/auto_blocking/3606043f-db40-4103-bbba-201f3c51db3a/da0128c5-daed-4bee-910f-39d0d95aedbc.js')
        document.head.appendChild(privacyScript)
        console.log('Script de Privacidade executado com sucesso')
      })
      .catch(() => {
        console.log('Falha em executar script de Privacidade')
      })
  },
  methods: {
    initPrivacyCookies () {
      const s = document.createElement('script')
      s.src = 'https://cdn-prod.securiti.ai/consent/cookie-consent-sdk.js'
      s.setAttribute('data-tenant-uuid', '3606043f-db40-4103-bbba-201f3c51db3a')
      s.setAttribute('data-domain-uuid', 'da0128c5-daed-4bee-910f-39d0d95aedbc')
      s.setAttribute('data-backend-url', 'https://app.securiti.ai')
      s.defer = true
      // eslint-disable-next-line camelcase
      const parent_node = document.head || document.body
      parent_node.appendChild(s)
      s.addEventListener('load', function () { window.initCmp() })
    }
  }
}

</script>

<style scoped>
.cmp-revoke-consent {
  position: fixed;
  right: 10px !important;
  left: auto !important;
}
</style>
