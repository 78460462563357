import store from '../store'

const toast = {
  error (message) {
    store.dispatch('notifier/showError', message)
  },
  success (message) {
    store.dispatch('notifier/showSuccess', message)
  }
}

export default toast
