import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pt from 'vuetify/es5/locale/pt'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { pt },
    current: 'pt'
  },
  theme: {
    themes: {
      light: {
        primary: '#d42526',
        secondary: '#002166',
        accent: '#ffbb00',
        error: '#d42526',
        customwhite: '#ffffff'
      }
    }
  }
})
