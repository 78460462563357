<template>
  <div>
    <v-menu
      bottom
      left
      offset-y
    >
      <template #activator="{ on }">
        <div
          class="menu-user"
          v-on="on"
        >
          <v-avatar>
            <v-img
              aspect-ratio="1"
              :src="userPhoto"
            />
          </v-avatar>
          <span class="ml-3">
            <span
              v-show="$vuetify.breakpoint.smAndUp"
              class="mr-3"
            >
              {{ user || 'Usuário Desconhecido' }}
            </span>
            <v-icon
              v-if="badge === 1"
              color="red"
            >mdi-email-variant</v-icon>
            <v-icon
              v-else
            >mdi-chevron-down</v-icon>
          </span>
        </div>
      </template>
      <v-list>
        <v-list-item
          link
          to="/Perfil"
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Perfil</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          to="/Elogios"
        >
          <v-list-item-icon>
            <v-icon>
              mdi-account-heart
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>Elogios recebidos</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          to="/contact/"
        >
          <v-list-item-icon>
            <v-icon>mdi-phone</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Meu Contato</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          to="/suggestionslist/"
        >
          <v-list-item-icon>
            <v-icon>mdi-comment-alert-outline</v-icon><v-badge
              v-if="badge === 1"
              class="badge"
              dot
            />
          </v-list-item-icon>
          <v-list-item-title>Sugestões/Reclamações</v-list-item-title>
        </v-list-item>
        <v-list-item
          link
          @click="logoutView"
        >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Sair</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import store from '../../store'
import Suggestion from '../../services/suggestion-service'
export default {
  data: () => ({
    user: '',
    badge:0,
  }),
  computed: {
    userPhoto () {
      return require('@/assets/no-image.png')
    }
  },
  async created () {
    this.user = store.getters['auth/userName']
    this.findStatusNotRead()
  },
  methods: {
    ...mapActions('auth', ['logout']),
    logoutView () {
      this.logout()
    },

    async findStatusNotRead(){
      const statusNotRead = await Suggestion.findStatusNotRead()
      this.badge = statusNotRead.status

    }
  }
}
</script>
