<template>
  <v-container fluid class="ma-0 pa-0">
    <v-app-bar
      app
      color="white"
      elevation="3"
      clipped-left
    >
      <v-app-bar-nav-icon
        v-show="$vuetify.breakpoint.smAndDown"
        class="secondary--text"
        @click.stop="mini = !mini"
      />
      <logo class="ml-3" />
      <v-spacer />
      <user-data />
    </v-app-bar>


    <v-navigation-drawer
      color="secondary"
      :mini-variant.sync="mini"
      permanent
      app
      dark
      clipped
    >
      <v-list-item
        v-show="$vuetify.breakpoint.mdAndUp"
        class="px-2"
      >
        <v-list-item-avatar>
          <v-btn
            v-show="mini"
            icon
            @click.stop="mini = !mini"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-avatar>

        <v-spacer />
  
        <v-btn
          icon
          @click.stop="mini = !mini"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider />
      <menu-items />
    </v-navigation-drawer>

    

    <v-main>
      <v-container
        fluid
        class="pa-0 ma-o"
      >
        <v-scroll-y-transition mode="out-in">
          <router-view />
        </v-scroll-y-transition>
      </v-container>
    </v-main>

    <PrivacyCookies />
  </v-container>
</template>

<script>
import MenuItems from "@/components/layout/MenuItems";
import Logo from "@/components/layout/Logo";
import UserData from "@/components/layout/UserData";
import PrivacyCookies from "../components/PrivacyCookies";

export default {
  components: {
    MenuItems,
    Logo,
    UserData,
    PrivacyCookies,
  },
  data() {
    return {
      mini: false,
    };
  },
};
</script>

<style scoped>
</style>