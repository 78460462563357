<template>
  <div>
    <v-list-group
      :value="true"
      prepend-icon="mdi-handshake"
    >
      <template #activator>
        <v-list-item-title
          class="text-uppercase font-weight-bold"
        >
          Links
        </v-list-item-title>
      </template>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        target="_blank"
        :href="item.link"
      >
        <v-list-item-icon>
          <v-icon> {{ item.icon }} </v-icon>
        </v-list-item-icon>
        <v-list-item-title> {{ item.text }} </v-list-item-title>
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
export default {
  name: 'MenuLinks',
  data: () => ({
    items: [
      {
        icon: '',
        text: 'AVANEP',
        link: 'https://avanep.cassems.com.br/'
      }
    ]
  })
}
</script>
