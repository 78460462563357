<template>
  <div>
    <v-list-group
      v-for="(item, i) in categories"
      :key="i"
      :value="true"
      prepend-icon="mdi-handshake"
    >
      <template #activator>
        <v-list-item-title
          class="text-uppercase font-weight-bold"
        >
          {{ item.name }}
        </v-list-item-title>
      </template>

      <v-list-item
        v-for="(itemJ, j) in item.arrayText"
        :key="j"
        class="py-2"
        :link="true"
        @click="redirectToText(item.slug ,itemJ.slug)"
      >
        <v-list-item-icon />

        <v-list-item-content>
          <v-list-item-title class="text-uppercase">
            {{ itemJ.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-group>
  </div>
</template>

<script>
import { getCategories, getInstitutionalTexts } from '../../services/institutional-service'

export default {
  name: 'SubMenu',
  data: () => ({
    categories: [],
    institutionalTexts: [],
    institutionalTextNoFilter: [],
    isLoading: false
  }),
  async created () {
    await this.loadInstitutionalTexts()
    this.loadCategories()
  },
  methods: {

    async loadCategories () {
      this.categories = await getCategories()
      let i
      for (i = 0; i < this.categories.length; i++) {
        const resultFilter = this.filterTextByCategories(this.categories[i].id)
        this.categories[i].arrayText = resultFilter
      }
    },

    async loadInstitutionalTexts () {
      this.institutionalTextNoFilter = await getInstitutionalTexts()
    },

    filterTextByCategories (categoryId) {
      return this.institutionalTextNoFilter.filter(text => text.category.id === categoryId)
    },

    async redirectToText (categorySlug, textSlug) {
      await this.$router.push({ path: `/ler/${categorySlug}/${textSlug}` })
      this.$router.go(this.$router.currentRoute)
    }
  }
}
</script>

<style scoped>
.v-application .primary--text {
    color: #ffffff !important;
    caret-color: #ffffff !important;
}
</style>
