<template>
  <div class="background">
    <div class="opac">
      <v-container fluid class="pa-0 ma-o">
        <v-scroll-y-transition mode="out-in">
          <router-view />
        </v-scroll-y-transition>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    pageName() {
      return this.$route.matched[this.$route.matched.length - 1].props.default
        .title;
    },
  },
};
</script>
