import External from "@/layouts/External";

export default {
  path: "/external",
  component: External,
  children: [
    {
      path: "compliance",
      name: "external.compliance",
      component: () => import("@/views/external/ComplianceExternal.vue"),
      meta: { requiresLogin: false },
      props: {
        title: "Compliance",
        targetReadRoute: "external/read-compliance",
      },
    },
    {
      path: "read-compliance/:slug",
      name: "external.readcompliamce",
      component: () => import("@/views/main/ReadCompliance.vue"),
      meta: { requiresLogin: false },
      props: {
        title: "Ler Compliance",
      },
    },
    {
      path: "news",
      name: "external.news",
      component: () => import("@/views/external/NewsExternal.vue"),
      meta: { requiresLogin: false },
      props: {
        title: "Notícias",
        targetReadRoute: "external/read-news",
      },
    },
    {
      path: "read-news/:slug",
      name: "external.readnews",
      component: () => import("@/views/main/ReadNews.vue"),
      meta: { requiresLogin: false },
      props: {
        title: "Ler Notícia",
      },
    },
    {
      path: "articles",
      name: "external.articles",
      component: () => import("@/views/external/ArticlesExternal.vue"),
      meta: { requiresLogin: false },
      props: {
        title: "Articles",
        targetReadRoute: "external/read-articles",
      },
    },
    {
      path: "read-articles/:slug",
      name: "external.readarticles",
      component: () => import("@/views/main/ReadArticles.vue"),
      meta: { requiresLogin: false },
      props: {
        title: "Ler Artigo",
      },
    },
    {
      path: "lgpd",
      name: "external.lgpd",
      component: () => import("@/views/external/LgpdExternal.vue"),
      meta: { requiresLogin: false },
      props: {
        title: "LGPD",
        targetReadRoute: "external/read-lgpd",
      },
    },
    {
      path: "read-lgpd/:slug",
      name: "external.readlgpd",
      component: () => import("@/views/main/ReadLgpd.vue"),
      meta: { requiresLogin: false },
      props: {
        title: "Ler LGPD",
      },
    },
  ],
};
