import Vue from 'vue'
import VueRouter from 'vue-router'
import main from './main'
import auth from './auth'
import external from './external'
import store from '../store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [main, auth, external],
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresLogin && !store.getters['auth/isLogged']) {
    store.dispatch('auth/logout')
  } else {
    next(next)
  }
})

export default router
