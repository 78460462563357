<template>
  <v-overlay
    opacity="0.3"
    :value="visible"
  >
    <div class="d-flex d-flex-column justify-center">
      <v-progress-circular
        :width="8"
        size="64"
        indeterminate
        color="primary"
      />
    </div>
  </v-overlay>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    visible: Boolean
  }
}
</script>