import Vue from 'vue'

import IconButton from './IconButton'
import Loader from '@/components/shared/Loader'
import Notifier from '@/components/shared/Notifier'
import ReadArticle from './ReadArticle'

Vue.component('IconButton', IconButton)
Vue.component('Loader', Loader)
Vue.component('Notifier', Notifier)
Vue.component('ReadArticle', ReadArticle)
