import Vue from 'vue'
import dayjs from 'dayjs'

Vue.filter('dateFormatted', (date) => dayjs(date).format('DD/MM/YYYY'))

Vue.filter('hourFormatted', (date) => `${dayjs(date).format('HH')}h${dayjs(date).format('mm')}`)

Vue.filter('stringLimit', function (value, size) {
  if (!value) return ''
  value = value.toString()

  if (value.length <= size) {
    return value
  }
  return value.substr(0, size) + '...'
})

Vue.filter('nameFormatted', (name) => {
  return name
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
})
